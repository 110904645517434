import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

const statuses = [
    'Pending', 'Approved', 'Rejected', 'Error', 'Uploaded'
  ];

  
  const statusItemTemplate = (option) => {
    if (option === "Pending")
        return (<span style={{ color: 'gray' }}>Pending</span>)
    else if (option === "Approved")
        return (<span style={{ color: 'lightgreen' }}> Approved</span>)
    else if (option === "Rejected")
        return (<span style={{ color: 'red' }}>Rejected</span>)
    else if (option === "Error")
        return (<span style={{ color: 'red' }}>Error</span>)
    else if (option === "Uploaded")
        return (<span style={{ color: 'green' }}>Uploaded</span>)
    else
        return (<span style={{ color: 'gray' }}>{option}</span>)
  }

  const dataTypeItemTemplate = (option) => {
        return (<span style={{ color: 'gray' }}>{option.value}</span>)
  }
  let data_types = [
    { id: 1, name: 'SME Loans', value: 'SME' },
    { id: 2, name: 'Consumer Loans', value: 'CONSUMER' },
    { id: 3, name: 'ECS Portfolio', value: 'ECS' },
    { id: 4, name: 'Cash Flows', value: 'CASH_FLOWS' },
    { id: 5, name: 'Opportunities', value: 'OPPORTUNITIES' },
    {  id: 6, name: 'Loan Applications', value: 'APPLICATIONS' },
    {  id: 7, name: 'Invoices', value: 'INVOICE_FINANCING' }
  ]
  const dataTypeFilterTemplate = (options) => {
    return <Dropdown value={options.value} optionValue="value" optionLabel="value"  options={data_types} 
    onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={dataTypeItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
  }

export const FILE_UPLOAD_COLUMNS = [
    {
        header: 'Name',
        field: 'file_name',
        sortable:true,
        filter: true
    },
    {
        header: 'Uploaded',
        field: 'upload_date',
        dataType:"date",
        sortable:true,
        filter: true,
        filterField:"upload_date",
        style:{ minWidth: '12rem' },
        body: (rowData) => {
            return rowData.upload_date.toLocaleString('en-GB', {
                weekday: "short",
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timezone: 'Europe/Berlin'
            });;
            // return rowData.upload_date.toString();
        },
        filterElement: (options) => {
            return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
          }
    },
    {
        header: 'Number Of Rows',
        field: 'num_of_rows',
        sortable:true,
        filter:true,
        dataType:"numeric"
    },
    {
        
        header: 'Data Type',
        field: 'data_type',
        sortable:true,
        filter:true,
        filterElement:dataTypeFilterTemplate,
        filterField:"data_type"
    },
    {
        
        header: 'Uploaded By',
        field: 'uploaded_by_user_email',
        sortable:true,
        filter:false
    },
    {
        header: 'Status',
        field: 'status',
        sortable:true,
        filter:true,
        filterField:"status",
        filterElement: (options) => {
    return <Dropdown  value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
  },
        body: rowData => {
            if (rowData.status === "Pending")
                return (<span style={{ color: 'gray' }}>Pending</span>)
            else if (rowData.status === "Approved")
                return (<span style={{ color: 'lightgreen' }}> Approved</span>)
            else if (rowData.status === "Rejected")
                return (<span style={{ color: 'red' }}>Rejected</span>)
            else if (rowData.status === "Error")
                return (<span style={{ color: 'red' }}>Error</span>)
            else if (rowData.status === "Uploaded")
                return (<span style={{ color: 'green' }}>Uploaded</span>)
            else
                return (<span style={{ color: 'gray' }}>{rowData.status}</span>)
        }
    }
]
