import React, { useState,useEffect } from "react";
import { isEmpty, isNull, isNil } from "lodash";
import Slidein from './FileUploadSlideIn';
import axiosWithAuth from "../../helpers/AxiosHelper";
import "./file_upload.css";
import { useAuth } from "../../hooks/useAuth";
import { FileUpload as FileUploader } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';

let data_types = [
  { id: 1, name: 'SME Loans', value: 'SME' },
  { id: 2, name: 'Consumer Loans', value: 'CONSUMER' },
  { id: 3, name: 'ECS Portfolio', value: 'ECS' },
  { id: 4, name: 'Cash Flows', value: 'CASH_FLOWS' },
  { id: 5, name: 'Opportunities', value: 'OPPORTUNITIES' },
  {  id: 6, name: 'Loan Applications', value: 'APPLICATIONS' },
  {  id: 7, name: 'Invoices', value: 'INVOICE_FINANCING' }
]

let max_file_size = 400000000

// let upload_platforms = [
//   { id: 1, name: 'Acredius', value: 'Acredius' },
//   { id: 2, name: 'Bondster', value: 'Bondster' },
//   { id: 3, name: 'Colectual', value: 'Colectual' },
//   { id: 4, name: 'Conda', value: 'Conda' },
//   { id: 5, name: 'Creditshelf', value: 'Creditshelf' },
//   { id: 6, name: 'Demo', value: 'Demo' },
//   { id: 7, name: 'evenfi', value: 'evenfi' },
//   { id: 8, name: 'FinBee', value: 'FinBee' },
//   { id: 9, name: 'Finomark', value: 'Finomark' },
//   { id: 10, name: 'Geldvoorelkaar', value: 'Geldvoorelkaar' },
//   { id: 11, name: 'Gobifinance', value: 'Gobifinance' },
//   { id: 12, name: 'GoParity', value: 'GoParity' },
//   { id: 13, name: 'HiveFinance', value: 'HiveFinance' },
//   { id: 14, name: 'ITF_Group', value: 'ITF_Group' },
//   { id: 15, name: 'KaapitalOpMaat', value: 'KaapitalOpMaat' },
//   { id: 16, name: 'Kameo', value: 'Kameo' },
//   { id: 17, name: 'Lend', value: 'Lend' },
//   { id: 18, name: 'Lendwise', value: 'Lendwise' },
//   { id: 19, name: 'Rendauto', value: 'Rendauto' },
//   { id: 20, name: 'swisspeers', value: 'swisspeers' },
//   { id: 21, name: 'Untapped Global', value: 'Untapped Global' },
//   { id: 21, name: 'glancecapital', value: 'glancecapital' },
//   { id: 22, name: 'Exalender', value: 'Exalender' }
// ]

const FileUpload = (props) => {

  const { platform, platform_config } = useAuth();
  // const [file_to_upload, setFileUpload] = useState(null);
  const [data_type, setDataType] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [upload_platform, setUploadPlatform] = useState('');
  const [data_types_select, setDataTypesSelect] = useState(data_types.filter(obj => checkDataTypes(obj)));
  const [platforms_list,setPlatformsList] = useState([])
  // const [file, setFile] = useState();

  // onFileChange = event => {
  //   this.setState({ file_to_upload: event.target.files[0] });
  //   // this.props.setLoading(true);
  // };
  // upload_platforms = [
  //   { id: 1, name: 'Acredius', value: 'Acredius' },
  //   { id: 2, name: 'Bondster', value: 'Bondster' },
  //   { id: 3, name: 'Colectual', value: 'Colectual' },
  //   { id: 4, name: 'Conda', value: 'Conda' },
  //   { id: 5, name: 'Creditshelf', value: 'Creditshelf' },
  //   { id: 6, name: 'Demo', value: 'Demo' },
  //   { id: 7, name: 'evenfi', value: 'evenfi' },
  //   { id: 8, name: 'FinBee', value: 'FinBee' },
  //   { id: 9, name: 'Finomark', value: 'Finomark' },
  //   { id: 10, name: 'Geldvoorelkaar', value: 'Geldvoorelkaar' },
  //   { id: 11, name: 'Gobifinance', value: 'Gobifinance' },
  //   { id: 12, name: 'GoParity', value: 'GoParity' },
  //   { id: 13, name: 'HiveFinance', value: 'HiveFinance' },
  //   { id: 14, name: 'ITF_Group', value: 'ITF_Group' },
  //   { id: 15, name: 'KaapitalOpMaat', value: 'KaapitalOpMaat' },
  //   { id: 16, name: 'Kameo', value: 'Kameo' },
  //   { id: 17, name: 'Lend', value: 'Lend' },
  //   { id: 18, name: 'Lendwise', value: 'Lendwise' },
  //   { id: 19, name: 'Rendauto', value: 'Rendauto' },
  //   { id: 20, name: 'swisspeers', value: 'swisspeers' },
  //   { id: 21, name: 'Untapped Global', value: 'Untapped Global' },
  //   { id: 21, name: 'glancecapital', value: 'glancecapital' },
  //   { id: 21, name: 'Vauraus', value: 'Vauraus' }
  // ]

  useEffect(() => {
    props.setLoading(true);
    axiosWithAuth.get("/platforms")
        .then(data => {
            console.log('Inside config')
            console.log(data)
            setPlatformsList(data.data)
        }).catch(err => {
            console.error(err);
            alert(err);
        }).finally(()=>{
            props.setLoading(false);
        });
}, []);

  const onFileUpload = (event) => {
    // event.preventDefault();
    console.log(event)
    console.log(event.files[0])
    let file_to_upload = event.files[0]
    setError('')
    if (!fileValidation(file_to_upload)) {
      setError('File size is too big. Cannot upload more than 200MB');
      return;
    }
    console.log()
    if (isNull(data_type) || isEmpty(data_type)) {
      setError('File type cannot be null.')
      return;
    }
    
    const formData = new FormData();
    formData.append(
      "uploadedFile",
      file_to_upload,
      file_to_upload.name
    );
    let selected_platform = platform
    if (platform === "Exaloan") {
      if (isNull(upload_platform) || isEmpty(upload_platform)) {
        setError('upload_platform cannot be null.')
        return;
      }
      selected_platform = upload_platform
    };
    formData.append("platform", selected_platform)
    formData.append("data_type", data_type)
    formData.append("upload_type", "platform_data")
    props.setLoading(true);
    console.log(formData);

    let v = axiosWithAuth.post('/files', formData).then(() => {
      let msg = `File ${file_to_upload.name} has been successfully uploaded`
      setSuccess(msg);
    }).catch((err) => {
      console.log(err.toJSON());
      if (err.message) {
        console.log(err.message)
        setError(err.message);
      }
    }).finally(() => {
      // file.value = null;
      // let file_temp = file;
      // file_temp.value = null;
      // setFile(file_temp);
      event.options.clear();
      setDataType('');
      setUploadPlatform('');
      props.setLoading(false);

    });
    console.log(v)
  };

  const fileValidation = (file) => {
    console.debug(file)
    console.log(file)
    if (file.size > max_file_size)
      return false;
    return true;
  }

  function checkDataTypes(obj) {
    if (!isNil(platform_config?.data_types)) {
      return platform_config.data_types.includes(obj.value) || obj.id === 0;
    } else {
      return obj.id === 0;
    }
  }

  const chooseOptions = { label: 'Browse', icon: 'pi pi-fw pi-plus' };
  const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <i className="pi pi-image mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
            <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Drag and Drop File Here</span>
            <small>Maximum file size: {max_file_size/(1000*1000)}MB.</small>
            <small>We accept .xlsx, .xls, .csv, .json files</small>
        </div>
    )
}

  return (
    <>
      <div className="upload">
        <div className='location'>
          <p>Check the <a href="/filetracking">File Tracking</a> page for information about your uploaded files.</p>
        </div>
        {error && <div name='error' className="errorMsg ">{error}</div>}
        {success && <div name='success' className="successMsg ">{success}</div>}
        <div className="p-fluid grid" style={{'maxWidth':'600px'}}>
        <div className="field col-12 ">
        <FileUploader name='loanbook' accept=".xlsx, .xls, .csv, .json" maxFileSize={max_file_size} chooseOptions={chooseOptions}  emptyTemplate={emptyTemplate} customUpload uploadHandler={onFileUpload} />
        </div>
        <div className="field col-12 ">
          <span className="p-float-label upload-form-items">
            <Dropdown inputId="dataType" value={data_type} optionLabel="name" optionValue='value' onChange={(e) => setDataType(e.value)} options={data_types_select} />
            <label htmlFor="dataType">Data Type</label>
          </span>
          <small id="dataType-help" >Select the type of data being uploaded.</small>
        </div>
          {(platform === "Exaloan") && <div className="field col-12 ">
            <span className="p-float-label upload-form-items">
              <Dropdown inputId="uploadPlatform" value={upload_platform} optionLabel="platform" optionValue='platform' onChange={(e) => setUploadPlatform(e.value)} options={platforms_list} />
              <label htmlFor="uploadPlatform">Platform to Upload for</label>
            </span>
            <small id="uploadPlatform-help" >Select the platform that you are uploading for.</small>
            </div>}
        </div>
        
        {/* <Box sx={{ minWidth: 120 }}>
          <FormControl>
          <Input labelId="browse-file-label" name='loanbook' id="file" accept= ".xlsx, .xls, .csv, .json" type="file" onChange={onFileChange}/>
          <InputLabel id="select-file-type-label">Loan Type</InputLabel>
            <Select
            labelId="select-file-type-label"
            id="select-file-type"
            value={file_type}
            >Loan Type
              {data_types.map(file => (
                <MenuItem 
                key={file.id}
                value={file.value}
                label="Loan Type"

                >{file.name}
                </MenuItem>
              ))}
            </Select>  
        </FormControl>
        </Box> */}
      </div>
      <Slidein data_types = {data_types_select.map(data_type => data_type.value)}/>
    </>
  );
};
export default FileUpload;
// div style={{width:"100%",position:"relative"}}